<template>
  <label class="hour-selector">
    <span class="hour-selector__label">{{ label }}</span>
    <select @change="$emit('change', $event.target.value)" ref="hour" v-model="valueLocal">
      <template v-for="el in HOURS">
        <option v-if="el" :value="el" :key="el">
          <!-- {{ el + parseInt(offset) == 24 ? "00" : el + parseInt(offset) }} -->
          {{ timeFormat(offsetApply(el + parseInt(offset)), "short") }}
        </option>
      </template>
    </select>
  </label>
</template>

<script>
import { HOURS, HOUR_START, HOUR_END } from "@/config/settings";
import { timeFormat, offsetApply } from "@/components-js/timeOffset";
export default {
  name: "HourSelector",
  props: { label: String, value: Number, offset: Number(0) },
  watch: {
    value: function(newVal) {
      this.valueLocal = newVal;
    },
  },
  data: function() {
    return {
      HOURS,
      HOUR_START,
      HOUR_END,
      valueLocal: null,
    };
  },
  created() {
    const hourCondition =
      this.$root?.user && (this.$root?.user?.business === "REBELS" || this.$root?.user?.business === "REBELS ++");
    if (hourCondition) {
      this.HOUR_START = 22;
      this.HOUR_END = 30;
      this.HOURS = [];
      for (let i = this.HOUR_START; i <= this.HOUR_END; i++) this.HOURS[i] = i;
    }
  },
  methods: {
    offsetApply(h) {
      return offsetApply(h);
    },

    timeFormat(time, flag) {
      return timeFormat(time, flag);
    },
  },
};
</script>

<style lang="scss" scoped>
.hour-selector {
  @media (max-width: 980px) {
    display: flex;
    width: 100%;
    margin-top: 6px;

    select {
      width: 100%;
    }

    &__label {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
    }
  }
}
</style>
