<template>
  <label class="minute-selector">
    <span class="minute-selector__label">{{ label }}</span>
    <select @change="$emit('change', $event.target.value)" ref="minute" v-model="valueLocal">
      <template v-for="el in MINUTES">
        <option :value="el" :key="el">
          <!-- {{ el + parseInt(offset) == 24 ? "00" : el + parseInt(offset) }} -->
          <!-- {{ offsetApply(el + parseInt(offset)) }} -->
          {{ el ? el : "00" }}
        </option>
      </template>
    </select>
  </label>
</template>

<script>
import { MINUTES } from "@/config/settings";
// import { timeFormat, offsetApply } from "@/components-js/timeOffset";
export default {
  name: "MinuteSelector",
  props: { label: String, value: [String, Number] /*, offset: Number */ },
  watch: {
    value: function(newVal) {
      this.valueLocal = newVal;
    },
  },
  data: function() {
    return {
      MINUTES,
      valueLocal: null,
    };
  },
  // methods: {
  //   offsetApply(h/*, l*/) {
  //     return (h/*, l*/);
  //   },
  //   timeFormat(time, flag){
  //     return timeFormat(time, flag);
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.minute-selector {
  @media (max-width: 980px) {
    display: flex;
    width: 100%;
    margin-top: 6px;

    select {
      width: 100%;
    }

    &__label {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
    }
  }
}
</style>
