<template>
  <div ref="chesstable_tbl">
    <TurnWindow
      :showFlag="showFlag"
      :turn="turn"
      :people="people"
      :jobs="jobs"
      :sales="sales"
      :salesShow="salesShow"
      @hide="windowTurnClose"
      :type="type"
      :dataSource="dataSource"
    />
    <table v-if="isSmallScreen" class="timetable__table t_table nominwidth" style="table-layout: fixed" :id="id">
      <tr class="timetable__header">
        <template v-for="(item, idx) in HOURS_VISUAL">
          <th v-if="item" :key="idx" class="timetable__cell __header t_cell t_cell_header draftday">
            {{ timeFormat(offsetApply(item), "short") }}
          </th>
        </template>
      </tr>

      <template v-for="(row, y) in tbl">
        <!--        <tr v-if="!row[row.length - 1]" :key="y + 'dd'">-->
        <!--          <td :colspan="HOUR_END - HOUR_START + 2" class="day-divider"></td>-->
        <!--        </tr>-->
        <tr :key="y + row[row.length - 2]">
          <td
            v-if="row[row.length - 1] === 0"
            class="timetable__cell __date t_date"
            :colspan="HOUR_END - HOUR_START"
            :class="{
              upper: !row[row.length - 1],
              middle: row[row.length - 1],
              t_date_current: isToday(row[row.length - 2]),
              __selected: isToday(row[row.length - 2]),
            }"
            ref="chesstable_date"
          >
            <div
              v-if="row[row.length - 1] === 0"
              @click.stop="addRowIntoTable(row[row.length - 2], null)"
              title="Добавить строку"
            >
              {{ row[row.length - 1] === 0 ? dateFormatHuman(row[row.length - 2], "w") : spacer }}
            </div>
          </td>
        </tr>
        <tr :key="y + row[row.length - 2] + 'dt'">
          <template v-for="(item, x) in HOURS_VISUAL">
            <td
              v-if="item"
              class="timetable__cell"
              :class="{
                __selected: row[x] === null,
              }"
              :key="x"
              :title="item"
              :data-row="y"
              :data-col="x"
              :ref="x === HOURS[HOUR_START] ? 'chesstable_cell' : null"
            >
              {{ 0 > parseInt(tbl[y][x]) ? tbl[y][x] * -1 : "" }}
            </td>
          </template>
        </tr>
      </template>
    </table>

    <table v-else class="timetable t_table nominwidth" style="table-layout: fixed" :id="id">
      <!-- <tr style="height: 0px; border: none">
        <td class="t_date" style="height: 0px; border: solid 1px white"></td>
        <template v-for="item in HOURS">
          <td
            v-if="item"
            :key="item"
            class="t_cell t_cell_header"
            style="height: 0px; border: solid 1px white"
          ></td>
        </template>
      </tr> -->

      <tr>
        <th class="t_date draftdate"></th>
        <template v-for="(item, idx) in HOURS_VISUAL">
          <th v-if="item" :key="idx" class="timetable__cell __header t_cell t_cell_header draftday">
            {{ timeFormat(offsetApply(item), "short") }}
          </th>
        </template>
      </tr>

      <template v-for="(row, y) in tbl">
        <!-- day divider  -->
        <!--        <tr v-if="!row[row.length - 1]" :key="y + 'dd'">-->
        <!--          <td :colspan="HOUR_END - HOUR_START + 2" class="day-divider"></td>-->
        <!--        </tr>-->
        <!-- turn row divider, y + date -->
        <tr :key="y + row[row.length - 2]">
          <td
            class="timetable__cell __date t_date"
            :class="{
              upper: !row[row.length - 1],
              middle: row[row.length - 1],
              t_date_current: isToday(row[row.length - 2]),
              __selected: isToday(row[row.length - 2]),
            }"
            ref="chesstable_date"
          >
            <div
              class="href"
              v-if="row[row.length - 1] === 0"
              @click.stop="addRowIntoTable(row[row.length - 2], null)"
              title="Добавить строку"
            >
              {{ row[row.length - 1] === 0 ? dateFormatHuman(row[row.length - 2], "w") : spacer }}
            </div>
          </td>

          <template v-for="(item, x) in HOURS_VISUAL">
            <td
              v-if="item"
              class="timetable__cell"
              :class="{
                __selected: row[x] === null,
              }"
              :key="x"
              :title="item"
              :data-row="y"
              :data-col="x"
              :ref="x === HOURS[HOUR_START] ? 'chesstable_cell' : null"
            >
              {{ 0 > parseInt(tbl[y][x]) ? tbl[y][x] * -1 : "" }}
            </td>
          </template>
        </tr>
      </template>
    </table>

    <Turn
      v-on:openEdit="windowTurnShow"
      v-on:highLight="highLight"
      :TURNS="TURNS"
      :people="people"
      :jobs="jobs"
      :date_begin_local="date_begin_local"
      :key="updateTrigger"
    />
    <Debug v-if="DEBUG" :showPeople="showPeople" :showTurns="showTurns" :showTbl="showTbl" />
  </div>
</template>

<script>
import ResizeObserver from "resize-observer-polyfill";
import { isMobile } from "@/lib/helpers/checkDevice";
import { request } from "@/components-js/requestSrv";
import { timeFormat, offsetApply } from "@/components-js/timeOffset";
import { dateFormatHuman } from "@/components-js/dateFormat";
import { DEBUG, HOURS, HOUR_START, HOUR_END, HOURS_VISUAL } from "@/config/settings";
import TurnWindow from "@/components/TurnWindow";
import Turn from "@/components/Turn";
import Debug from "@/components/Debug";

export default {
  name: "ChessItself",
  props: {
    autoTable: Boolean,
    tbl: Array,
    TURNS: Object,
    people: Array,
    jobs: Array,
    sales: Object,
    salesShow: Boolean,
    date_begin: String,
    id_point: [Number, String],
    turnDel: Number,
    id: null,
    type: null,
    // for to switch between draft and pointadmin draft
    dataSource: {
      type: String,
      default: "",
    },
  },
  components: {
    TurnWindow,
    Turn,
    Debug,
  },
  data() {
    return {
      showFlag: false,
      spacer: "\u00A0",
      // cell selection
      colSelected: null,
      rowSelected: null,
      markFlag: 0,
      date_begin_local: null,
      updateTrigger: 0,
      DEBUG,
      HOURS,
      HOURS_VISUAL,
      HOUR_START,
      HOUR_END,
      watcher: null,
      watcherOffsetLeft: 0,

      // cell selection
      turn: {
        id_turn: null,
        id_point: null,
        id_people: null,
        id_job: null,
        turn_date: null,
        hour_begin: null,
        hour_end: null,
        time_add: null,
        time_add_note: null,
        turn_order: null,
        turn_rate: null,
        orderAbs: null,
        status: null,
      },
      isLaptop: false,
    };
  },

  computed: {
    isSmallScreen() {
      return this.isMobile() || this.isLaptop;
    },
  },

  watch: {
    salesShow: function(newVal) {
      if (newVal) this.salesAdd(this.sales);
    },
  },

  created() {
    const hourCondition =
      this.$root?.user && (this.$root?.user?.business === "REBELS" || this.$root?.user?.business === "REBELS ++");
    if (hourCondition) {
      this.HOUR_START = 22;
      this.HOUR_END = 30;
      this.HOUR_END_VISUAL = 29;
      this.HOURS = [];
      this.HOURS_VISUAL = [];
      for (let i = this.HOUR_START; i <= this.HOUR_END; i++) this.HOURS[i] = i;
      for (let i = this.HOUR_START; i <= this.HOUR_END_VISUAL; i++) this.HOURS_VISUAL[i] = i;
    }
    // window.removeEventListener("resize", this.resize);
    window.addEventListener("resize", this.resize);
    // console.log("chessitself created");
    // additional redraw :(
    this.watcher = setInterval(() => this.resizeInterval(), 10);
  },

  destroyed() {
    window.removeEventListener("resize", this.resize);
    clearInterval(this.watcher);
  },

  mounted() {
    // resize watcher
    this.handleResizeSlider();
    const ResObs = new ResizeObserver((/*entries*/) => {
      this.updateTrigger++;
      // for (let entry of entries) {
      //   // const cr = entry.contentRect;
      //   console.log("Element:", entry.target);
      //   // console.log(`Element size: ${cr.width}px x ${cr.height}px`);
      //   // console.log(`Element padding: ${cr.top}px ; ${cr.left}px`);
    });
    // for chesstable
    ResObs.observe(this.$refs.chesstable_tbl);

    // --console.log("mounted ChessItself");
    // --console.log("sales", this.sales);
    // set mouse event handlers
    const elem = document.getElementById(this.id);
    // because main table can be absent
    if (elem && !this.autoTable) {
      elem.addEventListener("mousedown", this.selectPress);
    } else console.log("${this.id} NOT FOUND");

    // local copy of date_begin
    this.date_begin_local = this.date_begin;
  },

  methods: {
    handleResizeSlider() {
      if (window?.innerWidth < 1300 && !this.isLaptop) {
        this.isLaptop = true;
      }

      if (window?.innerWidth > 1300) {
        this.isLaptop = false;
      }
    },
    isMobile,
    offsetApply(h) {
      return offsetApply(h);
    },

    timeFormat(time, flag) {
      return timeFormat(time, flag);
    },

    resizeInterval() {
      // if (!this.$refs.chesstable_tbl) return;
      if (this.watcherOffsetLeft !== this.$refs.chesstable_tbl.offsetLeft) {
        this.watcherOffsetLeft = this.$refs.chesstable_tbl.offsetLeft;
        this.resize();
      }
    },

    resize() {
      this.updateTrigger++;
      // console.log("resize event");
    },
    /* DEBUG */
    showPeople() {
      this.people.forEach(el => console.log({ ...el }));
    },
    showTurns() {
      console.log(this.TURNS.getList());
    },
    showTbl() {
      console.log(this.tbl);
    },
    /* DEBUG */
    dateFormatHuman(d, w) {
      return dateFormatHuman(d, w);
    },

    salesAdd(inArr) {
      if (!this.$root?.user?.roles.includes("admin")) return;
      // console.log("inArr", inArr);
      // console.log("Object.keys(inArr).length", Object.keys(inArr).length);
      // if (Object.keys(inArr).length == 0) return [];
      for (const dt in inArr) {
        // through each date
        const salesPH = []; // sales per hour
        const salesPP = []; // sales per person
        let sum = 0;
        for (let i = 0; i < this.HOUR_START; i++) salesPH[i] = salesPP[i] = 0;
        for (let i = this.HOUR_START; i <= this.HOUR_END; i++) {
          salesPH[i] = inArr[dt][i];
          if (typeof salesPH[i] == "number") {
            // amount of sales non-empty
            sum += parseInt(salesPH[i]);
            // set negative sign for output filter
            salesPH[i] = -salesPH[i];
            // calculate sPP also
            // count unique turn_id in hour of current date
            const tmp = [];
            for (let k = 0; k < this.tbl.length; k++) {
              if (this.tbl[k][this.HOUR_END + 1] === dt)
                if (this.tbl[k][i] && !tmp.includes(this.tbl[k][i])) tmp.push(this.tbl[k][i]);
            }
            salesPP[i] = (salesPH[i] / tmp.length).toFixed(1);
          }
          // set negative sign for output filter
          if (i === this.HOUR_END) salesPH[i - 1] = -1 * sum;
        }
        this.addRowIntoTable(dt, salesPH);
        this.addRowIntoTable(dt, salesPP);
      }
    },

    isToday(d) {
      return dateFormatHuman(new Date()) === dateFormatHuman(d);
    },

    // add row into table for date, fill with arr
    async addRowIntoTable(date, arr) {
      // roles access
      const check = await this.accessCheck();
      if (!check) return;

      let lastOrder = 0;
      let idx = 0;

      // new row
      const addTbl = [];

      // fill new row with zeros
      for (let k = this.HOUR_START; k <= this.HOUR_END; k++)
        if (arr && arr[k] != undefined) addTbl[k] = arr[k];
        else addTbl[k] = 0;

      // add date at the end of new row
      addTbl.push(date);

      // get turns order at seleted date
      for (let x = 0; x < this.tbl.length; x++) {
        if (this.tbl[x][this.tbl[x].length - 2] === date) {
          lastOrder = this.tbl[x][this.tbl[x].length - 1];
          idx = x;
        }
      }
      // after last position
      lastOrder++;
      idx++;

      // add increased order at the end of new row
      addTbl.push(lastOrder);

      // insert new row
      this.tbl.splice(idx, 0, addTbl);
      this.TURNS.changeOrderAbs(date, 1);

      this.updateTrigger++;
    },

    // ---------------------------
    // table row selection start
    // ---------------------------
    async selectPress(evt) {
      //console.log("selectPress");
      // handle left mouse button only

      // roles access
      const check = await this.accessCheck();
      if (!check) return;

      // // not Admin
      // if (!this.$root.user.roles.includes("admin")) {
      //   // and not PointAdmin
      //   if (!this.$root.user.roles.includes("pointadmin")) return;
      //   // check point access
      //   const res = await request("/api/user/checkpointaccess", "POST", {
      //     uid: this.$root.user.uid,
      //     id: this.id_point,
      //   });

      //   if (!res.cnt) return;
      // }

      if (evt.which !== 1) {
        //cancelMark();
        return;
      }
      // mark mode just begin
      if (this.markFlag === 0) {
        // if the cell is not availiable for mark - exit
        if (!evt.target.hasAttribute("data-col") || !evt.target.hasAttribute("data-row")) {
          //console.log("non-usable cell");
          this.resetTurn();
          return;
        }

        // BEGIN HOUR has been selected
        this.markFlag = 1;

        // remeber selected row and col
        this.rowSelected = parseInt(evt.target.getAttribute("data-row"));
        this.colSelected = parseInt(evt.target.getAttribute("data-col"));

        // mark tbl cell
        this.tbl[this.rowSelected][this.colSelected] = null; // NaN -1
        this.$forceUpdate();

        this.resetTurn();

        // remember date
        this.turn.turn_date = this.tbl[this.rowSelected][this.tbl[this.rowSelected].length - 2];
        console.log('this.turn.turn_date', this.tbl);
        // remember begin hour
        this.turn.hour_begin = parseInt(evt.target.getAttribute("data-col"));

        // remember turn order
        this.turn.turn_order = this.tbl[this.rowSelected][this.tbl[this.rowSelected].length - 1];

        // remember orderAbs
        this.turn.orderAbs = this.rowSelected;

        return;
      } else {
        // mark mode second click
        if (
          // click not the same row, as at the begin
          parseInt(evt.target.getAttribute("data-row")) !== this.rowSelected ||
          // click on busy cell
          this.tbl[parseInt(evt.target.getAttribute("data-row"))][parseInt(evt.target.getAttribute("data-col"))] > 0 ||
          // click on cell behind another turn
          !this.checkTurn(
            undefined,
            parseInt(evt.target.getAttribute("data-col")),
            parseInt(evt.target.getAttribute("data-row"))
          )
        ) {
          // console.log("wrong cell, FORCE END");
          this.tbl[this.rowSelected][this.colSelected] = 0;
          // check for updateTrigger++
          this.$forceUpdate();
          this.resetTurn();
          this.markFlag = 0;
          return;
        }
        // END HOUR has been selected
        this.turn.hour_end = parseInt(evt.target.getAttribute("data-col"));
        // reverse if END less than BEGIN
        if (this.turn.hour_begin > this.turn.hour_end) {
          this.turn.hour_end += this.turn.hour_begin;
          this.turn.hour_begin = this.turn.hour_end - this.turn.hour_begin;
          this.turn.hour_end -= this.turn.hour_begin;
        }

        // set id_point
        this.turn.id_point = parseInt(this.id_point);

        this.markFlag = 0;
        // UNmark tbl cell
        this.tbl[this.rowSelected][this.colSelected] = 0;

        // open window with turn acception
        this.windowTurnShow();
      }
    },

    resetTurn() {
      this.turn.id_turn = null;
      this.turn.id_point = null;
      this.turn.id_people = null;
      this.turn.id_job = null;
      this.turn.turn_date = null;
      this.turn.hour_begin = null;
      this.turn.hour_end = null;
      this.turn.minute_begin = 0;
      this.turn.minute_end = 0;
      this.turn.time_add = 0;
      this.turn.time_add_note = null;
      this.turn.turn_order = null;
      this.turn.turn_rate = null;
      this.turn.orderAbs = null;
    },

    checkTurn(a = this.turn.hour_begin, b = this.turn.hour_end, y = this.rowSelected) {
      // check interval between to hours, for the absence of turn inside
      // select check direction
      const d = a < b ? 1 : -1;
      // console.log("check between " + a + " and " + b + " step " + d);

      for (let i = a; i !== b; i += d) {
        if (this.tbl[y][i] > 0) return false;
      }

      return true;
    },

    highLight(id = null) {
      if (!id) return;
      this.TURNS.highlightGroup(id);
      this.updateTrigger++;
    },

    // show turn window
    async windowTurnShow(id = null) {
      if (this.autoTable) return;

      // roles access
      const check = await this.accessCheck();
      if (!check) {
        // color change is a BIG trouble now
        // this.TURNS.highlightGroup(id);
        return;
      }

      if (id) {
        // turn selected
        this.turn.id_turn = id;
        this.turn.id_point = this.TURNS.getPoint(id);
        this.turn.id_people = this.TURNS.getPeople(id);
        this.turn.id_job = this.TURNS.getJob(id);
        this.turn.turn_date = this.TURNS.getDate(id);
        this.turn.hour_begin = this.TURNS.getHourBegin(id);
        this.turn.time_add = this.TURNS.getTimeAdd(id);
        this.turn.time_add_note = this.TURNS.getTimeAddNote(id);
        this.turn.hour_end = this.TURNS.getHourEnd(id);
        this.turn.minute_begin = this.TURNS.getMinuteBegin(id);
        this.turn.minute_end = this.TURNS.getMinuteEnd(id);
        this.turn.turn_order = this.TURNS.getOrder(id);
        this.turn.turn_rate = this.TURNS.getRate(id);
        this.turn.orderAbs = this.TURNS.getOrderAbs(id);
        this.turn.status = this.TURNS.getStatus(id);
      } else {
        // need increase hour_end
        this.turn.hour_end++;
      }
      this.showFlag = true;
    },

    // created turn from add window and what to do
    windowTurnClose(turn, action) {
      // turn is Object, passed by ref

      // if point is the same, then update instance of turn in turns[]
      if (action === "add" && this.id_point === turn.id_point) {
        // add new turn into turns[]
        // this.turns[turn.id_turn] = new Object();
        // Object.assign(this.turns[turn.id_turn], turn);
        this.TURNS.add(turn);
        for (let i = turn.hour_begin; i <= turn.hour_end; i++) this.tbl[this.rowSelected][i] = turn.id_turn;
      }
      if (action === "upd") {
        // unpaint old instance of turn
        for (let k = this.TURNS.getHourBegin(turn.id_turn); k <= this.TURNS.getHourEnd(turn.id_turn); k++)
          this.tbl[this.TURNS.getOrderAbs(turn.id_turn)][k] = 0;
        // if point is the same, then update instance in turns[]
        if (this.id_point === turn.id_point) {
          // Object.assign(this.turns[turn.id_turn], this.turn);
          this.TURNS.update(turn);
          // paint new instance of turn
          for (let i = parseInt(turn.hour_begin); i <= parseInt(turn.hour_end); i++) {
            this.tbl[this.TURNS.getOrderAbs(turn.id_turn)][i] = turn.id_turn;
          }
        } else {
          // point changed, delete turn
          this.TURNS.delete(turn.id_turn);
        }
      }
      if (action === "del") {
        // delete success, so
        // 1) remove from tbl array
        for (let k = this.TURNS.getHourBegin(turn.id_turn); k <= this.TURNS.getHourEnd(turn.id_turn); k++)
          this.tbl[this.TURNS.getOrderAbs(turn.id_turn)][k] = 0;
        // 2) erase in turns array
        this.TURNS.delete(turn.id_turn);
      }
      this.updateTrigger++;
      this.showFlag = false;
    },

    async accessCheck() {
      // if Admin let him do anything
      if (this.$root?.user?.roles.includes("admin")) return true;
      else {
        // not PointAdmin
        if (!this.$root?.user?.roles.includes("pointadmin")) return false;
        // check current point
        const res = await request("/api/user/checkpointaccess", "POST", {
          uid: this.$root?.user?.uid,
          id: this.id_point,
        });

        return !!parseInt(res.cnt);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.timetable {
  &__header {
    position: sticky;
    top: 48px;
  }
  &__cell {
    width: 100%;
    padding: 0;
    background-color: #eef5fc;
    &.__selected {
      background-color: #4196f0;
    }
    &.__header {
      background-color: #ffd596;
    }
    &.__date {
      &.__selected {
        background-color: #4196f0;
        color: white;
        .href {
          color: white;
        }
      }
    }
  }
  th:first-child {
    background-color: #eef5fc;
  }

  @media (max-width: 1300px) {
    &__table {
      border-collapse: separate;
    }
    &__cell {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      &.__header {
        padding: 0;
        border: 1px solid white;
      }
      &.__date {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}
</style>
