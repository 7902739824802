var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"chesstable_tbl"},[_c('TurnWindow',{attrs:{"showFlag":_vm.showFlag,"turn":_vm.turn,"people":_vm.people,"jobs":_vm.jobs,"sales":_vm.sales,"salesShow":_vm.salesShow,"type":_vm.type,"dataSource":_vm.dataSource},on:{"hide":_vm.windowTurnClose}}),(_vm.isSmallScreen)?_c('table',{staticClass:"timetable__table t_table nominwidth",staticStyle:{"table-layout":"fixed"},attrs:{"id":_vm.id}},[_c('tr',{staticClass:"timetable__header"},[_vm._l((_vm.HOURS_VISUAL),function(item,idx){return [(item)?_c('th',{key:idx,staticClass:"timetable__cell __header t_cell t_cell_header draftday"},[_vm._v(" "+_vm._s(_vm.timeFormat(_vm.offsetApply(item), "short"))+" ")]):_vm._e()]})],2),_vm._l((_vm.tbl),function(row,y){return [_c('tr',{key:y + row[row.length - 2]},[(row[row.length - 1] === 0)?_c('td',{ref:"chesstable_date",refInFor:true,staticClass:"timetable__cell __date t_date",class:{
            upper: !row[row.length - 1],
            middle: row[row.length - 1],
            t_date_current: _vm.isToday(row[row.length - 2]),
            __selected: _vm.isToday(row[row.length - 2]),
          },attrs:{"colspan":_vm.HOUR_END - _vm.HOUR_START}},[(row[row.length - 1] === 0)?_c('div',{attrs:{"title":"Добавить строку"},on:{"click":function($event){$event.stopPropagation();return _vm.addRowIntoTable(row[row.length - 2], null)}}},[_vm._v(" "+_vm._s(row[row.length - 1] === 0 ? _vm.dateFormatHuman(row[row.length - 2], "w") : _vm.spacer)+" ")]):_vm._e()]):_vm._e()]),_c('tr',{key:y + row[row.length - 2] + 'dt'},[_vm._l((_vm.HOURS_VISUAL),function(item,x){return [(item)?_c('td',{key:x,ref:x === _vm.HOURS[_vm.HOUR_START] ? 'chesstable_cell' : null,refInFor:true,staticClass:"timetable__cell",class:{
              __selected: row[x] === null,
            },attrs:{"title":item,"data-row":y,"data-col":x}},[_vm._v(" "+_vm._s(0 > parseInt(_vm.tbl[y][x]) ? _vm.tbl[y][x] * -1 : "")+" ")]):_vm._e()]})],2)]})],2):_c('table',{staticClass:"timetable t_table nominwidth",staticStyle:{"table-layout":"fixed"},attrs:{"id":_vm.id}},[_c('tr',[_c('th',{staticClass:"t_date draftdate"}),_vm._l((_vm.HOURS_VISUAL),function(item,idx){return [(item)?_c('th',{key:idx,staticClass:"timetable__cell __header t_cell t_cell_header draftday"},[_vm._v(" "+_vm._s(_vm.timeFormat(_vm.offsetApply(item), "short"))+" ")]):_vm._e()]})],2),_vm._l((_vm.tbl),function(row,y){return [_c('tr',{key:y + row[row.length - 2]},[_c('td',{ref:"chesstable_date",refInFor:true,staticClass:"timetable__cell __date t_date",class:{
            upper: !row[row.length - 1],
            middle: row[row.length - 1],
            t_date_current: _vm.isToday(row[row.length - 2]),
            __selected: _vm.isToday(row[row.length - 2]),
          }},[(row[row.length - 1] === 0)?_c('div',{staticClass:"href",attrs:{"title":"Добавить строку"},on:{"click":function($event){$event.stopPropagation();return _vm.addRowIntoTable(row[row.length - 2], null)}}},[_vm._v(" "+_vm._s(row[row.length - 1] === 0 ? _vm.dateFormatHuman(row[row.length - 2], "w") : _vm.spacer)+" ")]):_vm._e()]),_vm._l((_vm.HOURS_VISUAL),function(item,x){return [(item)?_c('td',{key:x,ref:x === _vm.HOURS[_vm.HOUR_START] ? 'chesstable_cell' : null,refInFor:true,staticClass:"timetable__cell",class:{
              __selected: row[x] === null,
            },attrs:{"title":item,"data-row":y,"data-col":x}},[_vm._v(" "+_vm._s(0 > parseInt(_vm.tbl[y][x]) ? _vm.tbl[y][x] * -1 : "")+" ")]):_vm._e()]})],2)]})],2),_c('Turn',{key:_vm.updateTrigger,attrs:{"TURNS":_vm.TURNS,"people":_vm.people,"jobs":_vm.jobs,"date_begin_local":_vm.date_begin_local},on:{"openEdit":_vm.windowTurnShow,"highLight":_vm.highLight}}),(_vm.DEBUG)?_c('Debug',{attrs:{"showPeople":_vm.showPeople,"showTurns":_vm.showTurns,"showTbl":_vm.showTbl}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }