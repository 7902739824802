<template>
  <label>
    {{ label }}
    <select @change="$emit('change', $event.target.value)" :value="value">
      <option v-for="el in HOURSADD" :value="el" :key="el">{{ el }}</option>
    </select>
  </label>
</template>

<script>
import { HOURSADD } from "@/config/settings";

export default {
  name: "HourSelectorAdd",
  props: { label: String, value: Number },
  data: function () {
    return {
      HOURSADD,
    };
  },
};
</script>
