var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.TURNS.getList()),function(turn,i){return [_c('div',{key:i,staticClass:"turn",class:turn.status,style:(_vm.positionCalc(turn)),attrs:{"title":_vm.title(
          turn.status,
          turn.id_people,
          turn.id_job,
          turn.p_daytime,
          turn.p_weekday,
          turn.p_jobtype,
          turn.p_status,
          'middle'
        )},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;$event.preventDefault();return _vm.$emit('openEdit', turn.id_turn)},"contextmenu":function($event){$event.preventDefault();return _vm.captionChange(turn.id_turn)}}},[_c('div',{staticClass:"turn__user-info"},[_vm._v(" "+_vm._s(turn.caption)+" "),_c('div',{staticClass:"base-tag ml-6",class:{ __default: !!turn.job_icon }},[(turn.turn_order === 0)?_c('object',{staticClass:"base-tag__object",attrs:{"type":"image/svg+xml","data":_vm.icoPath}},[_c('img',{attrs:{"src":"ico.png","alt":"svg ico"}})]):_vm._e(),(turn.job_icon)?_c('div',{staticClass:"base-tag__icon"},[_vm._v(_vm._s(turn.job_icon))]):_vm._e()]),_vm._v(" "+_vm._s(turn.time_add ? " +" + turn.time_add : null)+" ")])])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }