<template>
  <div class="debug" style="display: block">
    <a href="#" class="debug" v-on:click="showTbl()">tbl[]</a>
    <br />
    <a href="#" class="debug" v-on:click="showPeople()">people[]</a>
    <br />
    <a href="#" class="debug" v-on:click="showTurns()">turns[]</a>
  </div>
</template>

<script>
export default {
  name: "Debug",
  props: { showPeople: Function, showTurns: Function, showTbl: Function },
};
</script>
