<template>
  <div>
    <template v-for="(turn, i) in TURNS.getList()">
      <div
        :key="i"
        class="turn"
        :class="turn.status"
        :title="
          title(
            turn.status,
            turn.id_people,
            turn.id_job,
            turn.p_daytime,
            turn.p_weekday,
            turn.p_jobtype,
            turn.p_status,
            'middle'
          )
        "
        @click.left.prevent="$emit('openEdit', turn.id_turn)"
        @click.right.prevent="captionChange(turn.id_turn)"
        :style="positionCalc(turn)"
      >
        <div class="turn__user-info">
          {{ turn.caption }}
          <div class="base-tag ml-6" :class="{ __default: !!turn.job_icon }">
            <object v-if="turn.turn_order === 0" class="base-tag__object" type="image/svg+xml" :data="icoPath">
              <img src="ico.png" alt="svg ico" />
            </object>
            <div v-if="turn.job_icon" class="base-tag__icon">{{ turn.job_icon }}</div>
          </div>
          {{ turn.time_add ? " +" + turn.time_add : null }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { HOUR_START } from "@/config/settings";
import { CHESSTABLE_BORDER_WIDTH } from "@/config/design";
import { TURN_FREE_VAL } from "@/components-js/turns";

import { colorControl } from "@/components-js/colorControl";
import { COLORS_HIGHLIGHT_USAGE } from "@/config/design";
import { isMobile } from "@/lib/helpers/checkDevice";
const CC = new colorControl(COLORS_HIGHLIGHT_USAGE);

export default {
  name: "Turn",
  props: {
    TURNS: Object,
    people: Array,
    jobs: Array,
    // tbl: Array,
    // id_point: Number,
    // turnDel: Number,
    date_begin_local: String,
  },
  data() {
    return {
      spacer: '"\u00A0"',

      HOUR_START,
      CHESSTABLE_BORDER_WIDTH,
      isLaptop: false,
      hourCondition: false,
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      widthBorder: 0,
      icoPath: require("@/assets/junior.svg"),
    };
  },
  computed: {
    isSmallScreen() {
      return this.isMobile() || this.isLaptop;
    },
    borderWidth() {
      return this.isSmallScreen ? 1 : CHESSTABLE_BORDER_WIDTH;
    },
  },
  created() {
    this.hourCondition =
      this.$root?.user && (this.$root?.user?.business === "REBELS" || this.$root?.user?.business === "REBELS ++");
    if (this.hourCondition) {
      this.HOUR_START = 22;
    }
    // this for next opens
    this.redraw();
    CC.clearAll();
  },
  watch: {
    borderWidth: function(newVal) {
      this.top = this.$parent.$refs.chesstable_tbl.offsetTop + this.$parent.$refs.chesstable_cell[0].offsetTop + newVal;
    },
  },
  // updated() {
  //   // this is for first open
  //   // this.redraw();
  //   // console.log("updated");
  // },
  mounted() {
    this.handleResizeSlider();
  },
  methods: {
    handleResizeSlider() {
      const tableResolution = 1300;
      if (window?.innerWidth < tableResolution && !this.isLaptop) {
        this.isLaptop = true;
      }

      if (window?.innerWidth > tableResolution) {
        this.isLaptop = false;
      }
    },
    positionCalc(turn) {
      const getTop = () => {
        //общий отступ сверху, до первого работника
        const generalTopSpace = this.top;

        //высота строки
        const stringHeight = this.height;

        //порядковый номер строки, в одном дне может быть несколько строк
        const lineSequenceNumber = turn.orderAbs;

        //порядковый номер даты, равен разнице между первым днем из выбранного периода и текущим вычисляемым днём
        //работает толькло для экранов меньше 1300px на момент 26.04.24
        const dateSerialNumber = this.isSmallScreen ? this.dateDiff(this.date_begin_local, turn.turn_date) : 0;

        return generalTopSpace + (lineSequenceNumber + dateSerialNumber) * stringHeight + "px";
      };

      return {
        top: getTop(),
        left:
          (this.isSmallScreen ? 0 : this.left) +
          (turn.hour_begin - this.HOUR_START) * this.width +
          this.borderWidth +
          (turn.minute_begin / 60) * this.width +
          "px",
        width:
          Math.floor((turn.hour_end - turn.hour_begin) * this.width) +
          Math.floor((turn.minute_begin / 60) * this.width) +
          Math.floor((turn.minute_end / 60) * this.width) -
          this.borderWidth +
          "px",
        height: this.height - this.borderWidth * (this.isSmallScreen ? 2 : 1) + "px",
        backgroundColor: turn.highLight === "#193958" ? "#4196f0" : turn.highLight,
        color: turn.highLight === "#193958" ? "white" : turn.status === "free" ? "#ad0015" : "#0053ad",
      };
    },
    isMobile,
    // get days between dates
    dateDiff(date_begin, date_end) {
      return (new Date(date_end).getTime() - new Date(date_begin).getTime()) / 1000 / 60 / 60 / 24;
    },

    title(status, id_people, id_job, p_daytime, p_weekday, p_jobtype, p_status, type = "short") {
      if (status === TURN_FREE_VAL) {
        let res = "";
        if (p_daytime) res += p_daytime;
        if (p_weekday) res += "\n" + p_weekday;
        if (p_jobtype) res += "\n" + p_jobtype;
        if (p_status) res += "\n" + p_status;
        return res ? res : "свободная\nсмена";
      }

      if (!id_people) {
        console.log(status, id_people, id_job, p_daytime, p_weekday, p_jobtype, p_status);
        return "ошибка";
      }

      const ppl = this.people.find(e => e.id === id_people);
      const jobname = this.jobs.find(e => e.id === id_job).job_name;

      if (type === "short") return ppl.nickname ? ppl.nickname : ppl.surname + " " + ppl.name;
      if (type === "middle") return `${ppl.surname} ${ppl.name}\n${jobname}`;
      // if (type === "full")
      //   return `${ppl.surname} ${ppl.name} ${ppl.patronymic}`;

      return "error";
    },

    captionChange(id_turn) {
      // this.TURNS.rotateCaption(id_turn);
      // this.TURNS.highlightGroup(id_turn);

      // if it's a REAL table
      // !!! disgusting code !!!
      if (this.$parent.$options.propsData.type === "real") {
        const id_people = this.TURNS.getPeople(id_turn);
        const color = CC.getUserColor(id_people);
        // console.log("getUserColor", color);
        if (color) {
          CC.clearColor(color);
          // this.$root.$emit("markPerson", id_people, null);
          // this.setStyle(id_people);
          this.TURNS.highlightGroupByUser(id_people, null);
        } else {
          const color = CC.getFreeColor();
          // console.log("getFreeColor", color);
          if (color) {
            CC.setUserColor(id_people, color);
            // this.$root.$emit("markPerson", id_people, color);
            // this.setStyle(id_people);
            this.TURNS.highlightGroupByUser(id_people, color);
          }
        }
      }

      // if DRAFT, call from PeopleListDraft.vue
      if (this.$parent.$options.propsData.type === "draft")
        this.$root.$emit("turnClick", this.TURNS.getPeople(id_turn));
    },

    redraw() {
      // this is for REDRAW
      if (this.$parent.$refs.chesstable_tbl && this.$parent.$refs.chesstable_cell) {
        // const rect1 = this.$parent.$refs.chesstable_tbl.getBoundingClientRect();
        const rect2 = this.$parent.$refs.chesstable_cell[0].getBoundingClientRect();

        // this.top = rect1.top + rect2.top + CHESSTABLE_BORDER_WIDTH;
        this.top =
          this.$parent.$refs.chesstable_tbl.offsetTop +
          this.$parent.$refs.chesstable_cell[0].offsetTop +
          this.borderWidth;

        this.left =
          this.$parent.$refs.chesstable_date[0].offsetWidth +
          this.$parent.$refs.chesstable_date[0].offsetParent.offsetLeft;

        // console.log("this.$parent.$refs.chesstable_date[0].offsetWidth");
        // console.log(this.$parent.$refs.chesstable_date[0].offsetWidth);
        // console.log("this.$parent.$refs.chesstable_date[0].offsetLeft");
        // console.log(this.$parent.$refs.chesstable_date[0].offsetLeft);
        // console.log(
        //   "this.$parent.$refs.chesstable_date[0].offsetParent.offsetLeft"
        // );
        // console.log(
        //   this.$parent.$refs.chesstable_date[0].offsetParent.offsetLeft
        // );

        this.height = rect2.height;

        // this.height =
        //   this.$parent.$refs.chesstable_cell[0].offsetHeight -
        //   CHESSTABLE_BORDER_WIDTH;

        this.width = rect2.width;
        // this.width = this.$parent.$refs.chesstable_cell[0].offsetWidth;

        // console.log("this.height is ", this.height);
        // console.log("this.width is ", this.width);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.turn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  border-radius: 5px;
  &__user-info {
    display: flex;
    flex-direction: row;
  }

  &.closed {
    background-color: #dbedff;
    color: #0053ad;
  }

  &.free {
    justify-content: center;
    background-color: #ffe7ef;
    color: #ad0015 !important;
  }

  @media (max-width: 1300px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
}
.base-tag {
  display: flex;
  gap: 12px;
  flex-direction: row;
  width: max-content;
  &__icon {
    border-radius: 5px;
    background: var(--color-secondary-light-bg);
    justify-content: center;
    height: 20px;
    padding: 0 2px;
    font-size: 12px;
  }

  &__object {
    background-color: #dbedff;
    width: 14px;
    height: 20px;
  }
  @media (max-width: 980px) {
    padding: 2px 4px;
    &__object {
      width: 12px;
      height: 16px;
    }
  }
}
</style>
