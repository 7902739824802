<template>
  <div class="modal-view" v-show="showFlag">
    <div class="modal-view__wrapper">
      <div class="modal-view__header">
        <span v-if="turn.id_turn" class="modal-view__title">Редактировать смену</span>
        <span v-else class="modal-view__title">Добавить смену</span>
        <span class="modal-view__date">
          {{ dateFormatHuman(turn.turn_date, "w") }}
        </span>
      </div>
      <div class="flexR center">
        <PointSelector
          class="modal-view__selector"
          label="Адрес"
          :point-id="turn.id_point"
          @change="turn.id_point = parseInt($event)"
        />
      </div>
      <div class="modal-view__selector modal-view__label">Выберете тип смены</div>
      <div class="modal-view__buttons-wrapper">
        <button
          class="modal-view__button"
          :class="{ __primary: turn.status === TURN_FREE_VAL }"
          @click="turnStatusRotate"
        >
          Свободная
        </button>
        <button
          class="modal-view__button"
          :class="{ __primary: turn.status === TURN_CLOSE_VAL }"
          @click="turnStatusRotate"
        >
          Закрепленная
        </button>
      </div>
      <div v-if="turn.status === TURN_CLOSE_VAL">
        <div ref="human" class="modal-view__selector">
          <PeopleSelector
            label="Сотрудник"
            filter="work"
            :id_peopleIn="turn.id_people"
            @change="peopleSelectorHandler(parseInt($event))"
          />
          <span class="modal-view__label __absolute">{{ jobName }}</span>
        </div>
        <!--        <div class="flexR center">-->
        <!--          <select disabled v-model="turn.id_job">-->
        <!--            <option v-for="el in jobs" :value="el.id" :key="el.id">-->
        <!--              {{ el.job_name }}-->
        <!--            </option>-->
        <!--          </select>-->
        <!--        </div>-->
        <!-- &nbsp;&nbsp;&nbsp;
            <div class="flexR center">
              <input disabled :value="turn.turn_rate" style="width: 32px; text-align: center" />
            </div> -->
      </div>
      <div v-if="errMessage" class="err">{{ errMessage }}&nbsp;</div>
      <div ref="hours" class="modal-view__select-wrapper flexR center" style="justify-content: space-evenly">
        <div class="modal-view__selector">
          <div class="modal-view__label">Час нач.</div>
          <div class="flexR">
            <HourSelector label="" :offset="0" :value="turn.hour_begin" @change="turn.hour_begin = parseInt($event)" />
            <MinuteSelector
              label=""
              :offset="0"
              :value="turn.minute_begin"
              @change="turn.minute_begin = parseInt($event)"
            />
          </div>
        </div>
        <div class="modal-view__selector">
          <div class="modal-view__label">Час кон.</div>
          <div class="flexR">
            <HourSelector label="" :offset="0" :value="turn.hour_end" @change="turn.hour_end = parseInt($event)" />
            <MinuteSelector
              label=""
              :offset="0"
              :value="turn.minute_end"
              @change="turn.minute_end = parseInt($event)"
            />
          </div>
        </div>
      </div>
      <div class="modal-view__selector __checkbox flexR center">
        <span class="modal-view__label">Дополнительные часы</span>
        <input type="checkbox" v-model="timeAddFlag" :checked="timeAddFlag" style="vertical-align: middle" />
      </div>
      <div v-show="timeAddFlag" class="flexR center">
        <HourSelectorAdd :value="turn.time_add" @change="turn.time_add = parseInt($event)" />
        &nbsp;
        <textarea v-model="turn.time_add_note" />
        <br />
        <br />
      </div>

      <div class="modal-view__selector">
        <button v-if="turn.id_turn" v-on:click="turnDelete()" class="modal-view__button __red">Удалить</button>
      </div>
      <div class="modal-view__selector flexR">
        <button v-on:click="windowClose(0, null)" class="modal-view__button __neutral">Отмена</button>
        <button ref="applyButton" @click="Apply" class="modal-view__button __primary">
          {{ turn.id_turn ? "Применить" : "Добавить" }}
        </button>
      </div>
      <div class="modal-view__selector">
        <a v-if="turn.id_turn" href="" class="modal-view__label grey normal" @click.prevent="turnHistoryGet()">
          история
        </a>
        <div v-if="turnHistory.length">
          <div v-for="(el, i) in turnHistory" :key="i">
            {{ el.nick + ", " + el.date + ", " + offsetApply(el.hour_begin) + " - " + offsetApply(el.hour_end) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PointSelector from "@/components/PointSelector";
import PeopleSelector from "@/components/PeopleSelector.vue";
import HourSelector from "@/components/HourSelector";
import HourSelectorAdd from "@/components/HourSelectorAdd";
import MinuteSelector from "@/components/MinuteSelector";
import { dateFormatHuman } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";
import { TURN_CLOSE_VAL, TURN_FREE_VAL } from "@/components-js/turns";
import { timeFormat, offsetApply } from "@/components-js/timeOffset";

export default {
  name: "TurnWindow",
  components: { PointSelector, PeopleSelector, HourSelector, HourSelectorAdd, MinuteSelector },
  props: {
    showFlag: Boolean,
    turn: Object,
    people: Array,
    jobs: Array,
    type: null,
    dataSource: String,
  },

  data() {
    return {
      errMessage: null,
      TURN_CLOSE_VAL: TURN_CLOSE_VAL,
      TURN_FREE_VAL: TURN_FREE_VAL,
      timeAddFlag: false,
      turnHistory: [],
      route: null,
      id_peoplePrev: null,
    };
  },

  computed: {
    jobName() {
      return this.jobs.find(item => item.id === this.turn.id_job)?.job_name ?? "";
    },
  },

  mounted() {
    if (this.type === "real") this.route = "turn";
    if (this.type === "draft") this.route = "turndraft";
  },

  watch: {
    showFlag: function(newVal) {
      // clear turn history
      this.turnHistory = [];

      // window appeared, set default values
      if (newVal) {
        document.addEventListener("keyup", this.keyPressHandler);
        // time_add controls
        this.turn.time_add ? (this.timeAddFlag = true) : (this.timeAddFlag = false);

        // if (this.turn.status == TURN_FREE_VAL) {
        //   this.setFreeTurn();
        // }

        // window first open
        if (this.turn.status == null) {
          if (this.turn.id_people) {
            this.turn.status = TURN_CLOSE_VAL;
          } else {
            this.setFreeTurn();
          }
        }

        // existing turn opened
        if (this.turn.status === "closed") {
          if (this.turn.id_people) this.id_peoplePrev = this.turn.id_people;
          if (!this.turn.id_people && this.id_peoplePrev) this.peopleSelectorHandler(this.id_peoplePrev);
        }
      } else {
        document.removeEventListener("keyup", this.keyPressHandler);
      }
    },

    timeAddFlag: function(newVal) {
      if (!newVal) {
        this.turn.time_add = 0;
      }
    },

    // "turn.id_people": function (newVal) {
    //   console.log("turn.id_people", newVal);
    //   console.log("this.showFlag", this.showFlag);
    //   if (this.turn.status == TURN_FREE_VAL) return;
    //   // console.log("oldVal, newVal", oldVal, newVal);
    //   this.turn.id_job = this.people.find((el) => el.id == newVal).id_job;
    // },

    "turn.id_job": function(newVal) {
      if (this.turn.status === TURN_FREE_VAL || !newVal) return;
      this.turn.turn_rate = this.jobs.find(el => el.id === newVal).job_rate;
    },
  },

  methods: {
    offsetApply(h /*, l*/) {
      return offsetApply(h /*, l*/);
    },

    timeFormat(time, flag) {
      return timeFormat(time, flag);
    },

    async turnHistoryGet() {
      if (this.turnHistory.length) {
        this.turnHistory = [];
        return;
      }
      this.turnHistory = await request(`/api/${this.route}/history`, "POST", {
        id_turn: this.turn.id_turn,
      });
    },

    keyPressHandler(evt) {
      if (evt.code === "Escape") this.windowClose(0, null);
      if (evt.code === "Enter") this.Apply();
    },

    dateFormatHuman(d, w) {
      return dateFormatHuman(d, w);
    },

    peopleSelectorHandler(id_people) {
      if (id_people === null) return;
      this.turn.id_people = id_people;
      this.id_peoplePrev = id_people;
      if (this.people.length) this.turn.id_job = this.people.find(el => el.id === id_people).id_job;
    },

    setFreeTurn() {
      this.turn.status = TURN_FREE_VAL;
      this.turn.id_people = null;
      this.turn.id_job = null;
      this.turn.turn_rate = null;
    },

    setCloseTurn() {
      this.turn.status = TURN_CLOSE_VAL;
      this.peopleSelectorHandler(this.id_peoplePrev);
    },

    async Apply() {
      // check, that human is selected
      if (this.turn.status !== TURN_FREE_VAL && !this.turn.id_people) {
        this.$refs.human.classList.add("warn-border");
        setTimeout(() => this.$refs.human.classList.remove("warn-border"), 300);
        return;
      }

      // check for correct time interval include minutes
      if (
        parseInt(this.turn.hour_begin) * 60 + parseInt(this.turn.minute_begin) >=
        parseInt(this.turn.hour_end) * 60 + parseInt(this.turn.minute_end)
      ) {
        this.$refs.hours.classList.add("warn-border");
        setTimeout(() => {
          this.$refs.hours.classList.remove("warn-border");
        }, 500);
        return;
      }

      // disable button for prevent turn dupliction
      this.$refs.applyButton.disabled = true;

      // check for intersection with other turns
      if ((await this.turnMatches()) > 0) {
        this.$refs.applyButton.classList.add("warn");
        setTimeout(() => this.$refs.applyButton.classList.remove("warn"), 300);
        this.errMessage = "пересечение с другой сменой";
        setTimeout(() => (this.errMessage = null), 1000);
        this.$refs.applyButton.disabled = false;
        return;
      }

      if (this.turn.id_turn) {
        //update record
        this.turn.dataSource = this.dataSource; // add postfix for pointadmin draft
        const res = await request(`/api/${this.route}/update`, "POST", this.turn);
        delete this.turn.dataSource; // and remove it
        // multuturn for
        this.$root.$emit("turnUpdate", "update");
        this.$refs.applyButton.disabled = false;
        if (res.affectedRows) this.windowClose(this.turn, "upd");
      } else {
        // insert record
        this.turn.dataSource = this.dataSource; // add postfix for pointadmin draft
        const res = await request(`/api/${this.route}/insert`, "POST", this.turn);
        delete this.turn.dataSource; // and remove it
        this.$refs.applyButton.disabled = false;
        if (res.affectedRows === 1 && res.insertId) {
          // multuturn for
          this.$root.$emit("turnUpdate", "insert");
          this.turn.id_turn = res.insertId;
          this.windowClose(this.turn, "add");
        }
      }
    },

    windowClose(turnId, action) {
      this.$emit("hide", turnId, action);
    },

    async turnMatches() {
      this.turn.dataSource = this.dataSource; // add postfix for pointadmin draft
      const data = await request(`/api/${this.route}/validation`, "POST", this.turn);
      delete this.turn.dataSource; // and remove it
      console.log('turnMatches', data, this.route, this.turn);
      return parseInt(data[0].cnt);
    },

    turnStatusRotate() {
      this.turn.status = this.turn.status === TURN_CLOSE_VAL ? TURN_FREE_VAL : TURN_CLOSE_VAL;
      if (this.turn.status === TURN_FREE_VAL) {
        this.setFreeTurn();
      } else {
        this.setCloseTurn();
      }
    },

    async turnDelete() {
      // DB request
      if (!confirm("Удалить смену?")) return;
      const res = await request(`/api/${this.route}/delete`, "DELETE", {
        id_turn: this.turn.id_turn,
        dataSource: this.dataSource,
      });
      // delete fault
      if (res.affectedRows === 0) {
        // add ERROR effect !!!
        return;
      }
      // multuturn for
      this.$root.$emit("turnUpdate", "delete");
      this.windowClose(this.turn, "del");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease-in-out;
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  z-index: 11;

  &__wrapper {
    width: 24rem;
    background: #fff;
    margin: 70px auto;
    padding: 24px 16px;
    position: relative;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  &__date {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &__selector {
    position: relative;
    width: 100%;
    margin-top: 24px;

    &.__checkbox {
      justify-content: space-between;
    }
  }

  &__select-wrapper {
    gap: 24px;
  }

  &__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;

    &.__absolute {
      position: absolute;
      top: 55%;
      right: 30px;
    }
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    padding: 2px;
    border-radius: 5px;
    border: 2px solid var(--Neutral-200, #e2e8f0);
    background: var(--Neutral-50, #f8fafc);
    gap: 12px;
  }

  &__button {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    border: none;
    background: var(--Neutral-50, #f8fafc);

    &:first-child {
      margin-right: 12px;
    }

    &.__primary {
      border-radius: 4px;
      background: var(--Primary-Main, #0663c7);
      color: white;
    }

    &.__neutral {
      border-radius: 4px;
      background: white;
      color: black;
      border: 2px solid var(--Neutral-200, #e2e8f0);
    }

    &.__red {
      border-radius: 5px;
      background: var(--Red-Light-bg, #ffe7ef);
      color: var(--Red-Dark-main, #ad0015);
    }
  }

  input[type="checkbox"] {
    height: 24px;
    width: 24px;
  }

  @media (max-width: 980px) {
    &__wrapper {
      width: 100%;
      background: #fff;
      margin: 3rem 0;
      position: relative;
    }
  }
}

.grey {
  color: grey;
  text-decoration: underline;
}

textarea {
  display: block;
  height: 100%;
}
</style>
